import { graphql, useStaticQuery } from "gatsby"
import React from "react"

export const withOption = Component => ({ displayName = "Option", handleVariant, name, value, product }) => {
  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_LOW_STOCK {
      productTemplateData: sanityTemplateProduct {
        lowStockThreshold
      }
    }
  `)

  const { lowStockThreshold } = productTemplateData || {}

  const selectedVariant = product?.variants.find(variant => variant.selectedOptions.some(option => option.value === value && option.name === name))

  // Display Restock form or not when variant is out of stock, supported by Shopify App - "alert me! RESTOCK alerts"
  const displayRestock =
    product?.metafields?.find(({ key, namespace }) => namespace === "da-restock" && key === "hide")?.value === "false" ? false : true

  Component.displayName = displayName
  return (
    <Component
      handleVariant={handleVariant}
      name={name}
      value={value}
      displayRestock={displayRestock}
      selectedVariant={selectedVariant}
      lowStockThreshold={lowStockThreshold}
    />
  )
}
