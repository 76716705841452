import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withRelated = Component => ({ name = "Related", recommendations, relatedProducts, relatedCollection }) => {
  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_RELATED {
      productTemplateData: sanityTemplateProduct {
        additionalRelatedProductsTitle
      }
    }
  `)

  const { additionalRelatedProductsTitle } = productTemplateData || {}

  Component.displayName = name
  return (
    <Component
      title={additionalRelatedProductsTitle}
      recommendations={recommendations}
      relatedProducts={relatedProducts}
      relatedCollection={relatedCollection}
    />
  )
}
