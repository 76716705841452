import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCart } from "../../../../hooks/useCart"
import { useApp } from "../../../../hooks/useApp"

export const withGiftCardForm = Component => ({ name = "GiftCardForm", variants }) => {
  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_GIFT_CARD_FORM {
      productTemplateData: sanityTemplateProduct {
        addToCartButtonText
        settingGiftCardMaxLength
        additionalGiftCardCustomAmountPlaceholder
        additionalGiftCardCustomAmountText
        additionalGiftCardFormTitle
        additionalGiftCardLengthMessage
        additionalGiftCardMessageLabel
        additionalGiftCardMessagePlaceholder
        additionalGiftCardReceiverEmailLabel
        additionalGiftCardReceiverLabel
        additionalGiftCardSelectAmountLabel
        additionalGiftCardSenderLabel
        settingGiftCardPresetValue
      }
    }
  `)

  const {
    addToCartButtonText,
    settingGiftCardMaxLength,
    additionalGiftCardCustomAmountPlaceholder,
    additionalGiftCardCustomAmountText,
    additionalGiftCardFormTitle,
    additionalGiftCardLengthMessage,
    additionalGiftCardMessageLabel,
    additionalGiftCardMessagePlaceholder,
    additionalGiftCardReceiverEmailLabel,
    additionalGiftCardReceiverLabel,
    additionalGiftCardSelectAmountLabel,
    additionalGiftCardSenderLabel,
    settingGiftCardPresetValue,
  } = productTemplateData || {}

  const {
    config: {
      settings: { giftCardConstraints },
    },
  } = useApp()

  const { addGiftCardToCart, loading } = useCart()

  const [data, setData] = useState({
    amount: "",
    receiver: "",
    sender: "",
    email: "",
    message: "",
  })

  const handleClick = value => {
    setData(prev => ({
      ...prev,
      amount: value,
    }))
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const remainingWords = settingGiftCardMaxLength - data.message.length

  const [error, setError] = useState("")

  const handleSubmit = async (event, selectedVariant) => {
    event.preventDefault()

    await addGiftCardToCart(selectedVariant.id, 1, [
      {
        key: giftCardConstraints.RECEIVER,
        value: data.receiver,
      },
      {
        key: giftCardConstraints.SENDER,
        value: data.sender,
      },
      {
        key: giftCardConstraints.RECEIVER_EMAIL,
        value: data.email?.trim(),
      },
      {
        key: giftCardConstraints.MESSAGE,
        value: data.message,
      },
    ])
  }

  Component.displayName = name
  return (
    <Component
      data={data}
      error={error}
      handleChange={handleChange}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      remainingWords={remainingWords}
      loading={loading}
      settingGiftCardMaxLength={settingGiftCardMaxLength}
      addToCartButtonText={addToCartButtonText}
      additionalGiftCardCustomAmountPlaceholder={additionalGiftCardCustomAmountPlaceholder}
      additionalGiftCardCustomAmountText={additionalGiftCardCustomAmountText}
      additionalGiftCardFormTitle={additionalGiftCardFormTitle}
      additionalGiftCardLengthMessage={additionalGiftCardLengthMessage}
      additionalGiftCardMessageLabel={additionalGiftCardMessageLabel}
      additionalGiftCardMessagePlaceholder={additionalGiftCardMessagePlaceholder}
      additionalGiftCardReceiverEmailLabel={additionalGiftCardReceiverEmailLabel}
      additionalGiftCardReceiverLabel={additionalGiftCardReceiverLabel}
      additionalGiftCardSelectAmountLabel={additionalGiftCardSelectAmountLabel}
      additionalGiftCardSenderLabel={additionalGiftCardSenderLabel}
      settingGiftCardPresetValue={settingGiftCardPresetValue}
      variants={variants}
    />
  )
}
