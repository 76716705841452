import tw, { styled } from "twin.macro"
import { NextArrow, PrevArrow } from '../../../Styled/Slider'

export const Content = tw.div`
  w-full
`

export const Prev = styled(PrevArrow)`
  ${tw`text-white`}
`

export const Next = styled(NextArrow)`
  ${tw`text-white`}
`
