import tw, { styled } from "twin.macro"
import { StyledButton } from "../../../Styled/Button"
import { Body } from "../../../Styled/Text"

export const Label = styled(Body)`
  ${tw`mb-0-8`}
`

export const Message = tw.p`
  mb-0-8 text-10 text-grey block
`

export const ErrorMessage = tw.p`
  mb-0-8 text-10 text-red block
`

export const PresetAmountWrapper = tw.div`
  w-full grid grid-cols-5 gap-1 mb-1-4
`

export const PresetAmount = styled.button`
  ${tw`text-12 border py-0-5`}
  ${({ active }) => (active ? tw`border-black bg-black text-white` : tw`border-grey-dark`)}
`

export const AddToCartButton = styled(StyledButton)`
  ${tw`mt-0-8`}
`

export const ButtonInnerWrapper = tw.div`
  flex flex-row justify-center items-center gap-0-8
`

export const NameWrapper = tw.div`
  grid grid-cols-2 gap-1 
`

export const StyledSelect = styled.select`
  ${tw`block appearance-none outline-none w-full border bg-transparent border-grey text-12 px-1-2 py-0-8 transition-all duration-200 placeholder-black placeholder-opacity-100 mb-1-2 rounded-none`}
`

export const StyledSelectOption = tw.option``
