import React from "react"
import { withFeatures } from "./withFeatures"
import { Wrapper, Feature, Caption, StyledImage } from "./FeaturesStyles"

export const Features = withFeatures(({ features }) =>
  features.length > 0 ? (
    <Wrapper>
      {features.map(feature => (
        <Feature key={feature._key}>
          <StyledImage image={feature.icon} />
          <Caption>{feature.title}</Caption>
        </Feature>
      ))}
    </Wrapper>
  ) : null
)
