import React from "react"

import { withSizeGuide } from "./withSizeGuide"
import { Wrapper, Title, Description, Tables, Space } from "./SizeGuideStyles"
import { Table } from "./Table"
import { UnderlinedButton } from "../../Styled/Button"
import { Modal, ModalOpenButton, ModalContentsWithLayout } from "../../Modal/Modal"
import { RichText } from "../../RichText/RichText"
import tw from "twin.macro"

export const SizeGuide = withSizeGuide(({ showSizeGuide, sizeGuide, description, contentAbove, tables, content }) => {
  return showSizeGuide ? (
    <Modal>
      <Wrapper>
        <ModalOpenButton>
          <UnderlinedButton noMargin={true}>
            <span>Size Guide</span>
          </UnderlinedButton>
        </ModalOpenButton>
      </Wrapper>
      <ModalContentsWithLayout layout={"sizeGuide"}>
        <Title noBottomSpace={!description}>{sizeGuide.title}</Title>
        {description && <Description bottomSpace={contentAbove ? false : true}>{description}</Description>}
        {contentAbove && <RichText spacing="small" css={[description ? tw`py-2` : tw`pb-2`]}>{contentAbove}</RichText>}
        <Tables>
          {tables?.map(table => (
            <Table sizeGuide={sizeGuide} key={table._key} chart={table.table} />
          ))}
        </Tables>
        {content && <RichText>{content}</RichText>}
      </ModalContentsWithLayout>
    </Modal>
  ) : null
})
