import React from "react"
import { useApp } from "../../../hooks/useApp"

export const withCode = Component => ({ name = "Code", tags }) => {
  const {
    config: {
      settings: {
        tags: { productCode },
      },
    },
  } = useApp()

  const code = tags?.filter(tag => tag.includes(productCode))?.map(tag => tag.split(":")?.[1])

  Component.displayName = name
  return <Component code={code} />
}
