import React from "react"

import { withMoreInformation } from "./withMoreInformation"
import { Wrapper } from "./MoreInformationStyles"
import { Accordion } from "../../Accordion/Accordion"
import { RichText } from "../../RichText/RichText"

export const MoreInformation = withMoreInformation(
  ({ spec, care, fit, modelWearing, moreInfo, additionalProductSpecText, additionalProductCareText, additionalProductFitText }) => (
    <Wrapper>
      {spec?.length > 0 ? (
        <Accordion layout={"info"} title={additionalProductSpecText}>
          <RichText>{spec}</RichText>
        </Accordion>
      ) : null}
      {care ? (
        <Accordion layout={"info"} title={additionalProductCareText}>
          {care}
        </Accordion>
      ) : null}
      {fit ? (
        <Accordion layout={"info"} title={additionalProductFitText}>
          {fit}
          <br />
          {modelWearing}
        </Accordion>
      ) : null}
      {moreInfo.map(info => (
        <Accordion key={info._key} layout={"info"} title={info.question}>
          <RichText>{info.answer}</RichText>
        </Accordion>
      ))}
    </Wrapper>
  )
)
