import React from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { withPopup } from "./withPopup"
import { Content, Prev, Next } from "./PopupStyles"
import { Image } from "../../../Image/Image"
import { Icon } from "../../../Icon/Icon"
import { Video } from "../../../Video/Video"
import { ModalExternal, ModalContentsWithLayout } from "../../../Modal/Modal"
SwiperCore.use([Navigation])

export const Popup = withPopup(({ active, setActive, setSwiper, media }) => (
  <ModalExternal isOpen={active} setIsOpen={setActive}>
    <ModalContentsWithLayout layout={"productImages"}>
      <Content>
        <Prev id={"desktop-prev"} title={"previous image"}>
          <Icon name={"chevronLeft"} width={16} height={28} />
        </Prev>
        <Next id={"desktop-next"} title={"next image"}>
          <Icon name={"chevronRight"} width={16} height={28} />
        </Next>
        <Swiper
          navigation={{
            prevEl: "#desktop-prev",
            nextEl: "#desktop-next",
          }}
          lazy={true}
          preloadImages={false}
          onSwiper={setSwiper}
        >
          {media.map(item => (
            <SwiperSlide key={item.id}>
              {item?.image?.src ? (
                <Image image={item?.image} ratio={"8/11"} />
              ) : (
                <Video url={item?.sources?.[1]?.url} image={item?.previewImage} ratio={"8/11"} autoplay loop />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Content>
    </ModalContentsWithLayout>
  </ModalExternal>
))
