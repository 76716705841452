import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const Wrapper = styled.div<WrapperProps>`
  ${tw`p-0-3 mr-0-6`}
  ${({ active }) => (active ? tw`border border-black rounded-full` : null)}
`

export const Colour = styled.div<ColourProps>`
  ${tw`block w-2-4 h-2-4 border border-transparent rounded-full`}
  ${({ image }) => {
    return css`
      background-image: url(${image});
      background-color: #ffffff;
    `
  }}
`

type WrapperProps = {
  active: boolean
}

type ColourProps = {
  colourHexCode: string
}
