import React from "react"

import { Wrapper, SelectedOption, IconWrapper, Options } from "./VariantStyles"
import { Icon } from "../../../Icon/Icon"
import { withVariant } from "./withVariant"
import { Option } from "./Option/Option"

export const Variant = withVariant(({ active, handleClick, selectedOptions, option, handleVariant, product }) => (
  <Wrapper onClick={handleClick}>
    <SelectedOption active={active}>
      <div>
        {selectedOptions.some(({ name }) => name === option.name)
          ? `${option.name}: ${selectedOptions.find(({ name }) => name === option.name)?.value}`
          : `Select ${option.name}`}
      </div>
      <IconWrapper active={active}>
        <Icon name={active ? `arrowDropdownActive` : `arrowDropdown`} />
      </IconWrapper>
    </SelectedOption>

    <Options active={active}>
      {option?.values?.map((value, index) => (
        <Option key={value?.toString() + index} name={option.name} value={value} product={product} handleVariant={handleVariant} />
      ))}
    </Options>
  </Wrapper>
))
