import tw, { styled } from "twin.macro"
import { Body } from "../../Styled/Text"
import { Image } from "../../Image/Image"

export const Wrapper = tw.ul`
  list-none pb-1
`

export const Feature = tw.li`
  flex flex-row items-center mb-1
`

export const StyledImage = styled(Image)`
  ${tw`w-2-4 max-w-2-4 mr-1`}
`

export const Caption = styled(Body)``
