import React from "react"

import { withShare } from "./withShare"
import { Title, Outer, Inner, StyledIcon } from "./ShareStyles"

export const Share = withShare(({ title, methods, handleClick }) => (
  <Outer>
    <Title>{title}</Title>
    <Inner>
      {methods.map(method => (
        <StyledIcon key={method._key} name={method.name} title={method.name} handleClick={() => handleClick(method.name)} width={14} height={14} />
      ))}
    </Inner>
  </Outer>
))
