import React from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { withMobileImages } from "./withMobileImages"
import { Wrapper, Prev, Next, PlaceHolder } from "./MobileImagesStyles"
import { Image } from "../../../Image/Image"
import { Icon } from "../../../Icon/Icon"
import { Video } from "../../../Video/Video"

SwiperCore.use([Navigation])

export const MobileImages = withMobileImages(({ media }) => (
  <Wrapper>
    <Prev id={"mobile-prev"} title={"previous image"}>
      <Icon name={"chevronLeft"} width={16} height={28} />
    </Prev>
    <Next id={"mobile-next"} title={"next image"}>
      <Icon name={"chevronRight"} width={16} height={28} />
    </Next>
    <Swiper
      navigation={{
        prevEl: "#mobile-prev",
        nextEl: "#mobile-next",
      }}
      lazy={true}
      preloadImages={false}
      slidesPerView={1}
    >
      {media.length > 0 ? (
        media.map(item => (
          <SwiperSlide key={item?.id}>
            {item?.image?.src ? (
              <Image image={item?.image} ratio={"8/11"} />
            ) : (
              <Video url={item?.sources?.[2]?.url} image={item?.previewImage} ratio={"8/11"} loop />
            )}
          </SwiperSlide>
        ))
      ) : (
        <PlaceHolder />
      )}
    </Swiper>
  </Wrapper>
))
