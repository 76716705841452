import React, { useState } from "react"

export const withVariant = Component => ({ name = "Variant", selectedOptions, option, handleVariant, product }) => {
  const [active, setActive] = useState(false)

  const handleClick = () => {
    setActive(prev => !prev)
  }

  Component.displayName = name
  return (
    <Component
      active={active}
      handleClick={handleClick}
      selectedOptions={selectedOptions}
      option={option}
      handleVariant={handleVariant}
      product={product}
    />
  )
}
