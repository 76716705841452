import React from "react"

import { useShopify } from "../../../hooks/useShopify"

export const withImages = Component => ({ name = "Images", media }) => {
  const { imageNormaliser } = useShopify()
  const filteredMedia = media.map(item => {
    if (item.mediaContentType === "IMAGE") {
      return {
        ...item,
        image: imageNormaliser(item.image, 1440),
      }
    } else {
      return {
        ...item,
        previewImage: imageNormaliser(item.previewImage, 1440),
      }
    }
  })

  Component.displayName = name
  return <Component media={filteredMedia} />
}
