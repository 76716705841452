import React from "react"

import { withDescription } from "./withDescription"
import { Wrapper, RichText } from "./DescriptionStyles"

export const Description = withDescription(({ description }) => (
  <Wrapper>
    <RichText dangerouslySetInnerHTML={{ __html: description }} />
  </Wrapper>
))
