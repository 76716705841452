import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useApp } from "../../../hooks/useApp"
import { useShopify } from "../../../hooks/useShopify"

export const withSizeGuide = Component => ({ name = "SizeGuide", tags }) => {
  const {
    config: {
      settings: { tags: configTags },
    },
  } = useApp()

  const { edgeNormaliser } = useShopify()

  const { sizeGuides } = useStaticQuery(graphql`
    query SANITY_SIZE_CHARTS {
      sizeGuides: allSanitySizeGuide {
        edges {
          node {
            title
            description: _rawDescription(resolveReferences: { maxDepth: 2 })
            descriptionMobile: _rawDescriptionMobile(resolveReferences: { maxDepth: 2 })
            tables: _rawTables(resolveReferences: { maxDepth: 2 })
            tablesMobile: _rawTablesMobile(resolveReferences: { maxDepth: 2 })
            contentAbove: _rawContentAbove(resolveReferences: { maxDepth: 8 })
            contentAboveMobile: _rawContentAboveMoble(resolveReferences: { maxDepth: 8 })
            content: _rawContent(resolveReferences: { maxDepth: 8 })
            contentMobile: _rawContentMobile(resolveReferences: { maxDepth: 8 })
            col1Color {
              hex
            }
            row1Color {
              hex
            }
            row2Color {
              hex
            }
            restRowsColor {
              hex
            }
          }
        }
      }
    }
  `)

  const defaultSizeGuideHandle = "A size chart"
  const sizeGuideTags = [
    defaultSizeGuideHandle, 
    ...tags
      ?.filter(tag => tag.includes(configTags.sizeGuide))
      ?.map(tag => tag.split(":")?.[1])
  ]


  const defaultSizeGuide = null

  const selectedSizeGuide = edgeNormaliser(sizeGuides)?.filter(guide => sizeGuideTags.includes(guide?.title.trim()))?.[0] || defaultSizeGuide

  const showSizeGuide = Boolean(selectedSizeGuide)

  const isMobile = typeof window !== "undefined" && window.innerWidth < 768

  const description = isMobile ? selectedSizeGuide?.descriptionMobile || selectedSizeGuide?.description : selectedSizeGuide?.description
  const contentAbove = isMobile ? selectedSizeGuide?.contentAboveMobile || selectedSizeGuide?.contentAbove : selectedSizeGuide?.contentAbove
  const tables = isMobile ? selectedSizeGuide?.tablesMobile || selectedSizeGuide?.tables : selectedSizeGuide?.tables
  const content = isMobile ? selectedSizeGuide?.contentMobile || selectedSizeGuide?.content : selectedSizeGuide?.content

  Component.displayName = name
  return (
    <Component
      showSizeGuide={showSizeGuide}
      sizeGuide={selectedSizeGuide}
      description={description} 
      contentAbove={contentAbove}
      tables={tables}
      content={content}
    />
  )
}
