import tw, { styled, css } from "twin.macro"
import { RichText } from "../../RichText/RichText"

export const Wrapper = tw.div`
  pt-1-8 pb-1-2
`

export const Title = styled.h3`
  ${tw`text-20 leading-1.5 uppercase tracking-10 font-semibold text-center`}
  ${(props: any) => {
    return props.noBottomSpace ? tw`mb-1 md:mb-0` : tw`mb-2-4 md:mb-3-2`
  }}
`

export const Description = styled(RichText)`
  ${tw`pb-2-4 border-b border-black`}
  ${props => {
    return props.bottomSpace ? tw`mb-2-4 md:mb-3-2` : ``
  }}
`

export const Tables = tw.div`
  flex flex-col md:flex-row
`

export const Space = tw.div`
  py-2
`

export const SpaceBelow = tw.div`
  pb-2
`

export const Scroll = styled.div`
  ${tw`w-full overflow-y-hidden overflow-x-auto border-t border-l border-beige`}
`
export const Table = tw.div`table w-full`
export const Row = styled.div`
  ${tw`table-row -mx-1-2 items-center justify-between`}
  ${props => {
    return css`
      :nth-child(1) {
        background: ${props?.sizeGuide?.row1Color?.hex} !important;
        font-weight: bold;
      }
      :nth-child(2) {
        background: ${props?.sizeGuide?.row2Color?.hex} !important;
      }
      background: ${props?.sizeGuide?.restRowsColor?.hex} !important;
    `
  }}
`
export const Column = styled.div`
  ${tw`table-cell p-1-2 text-14 text-center border-r border-b border-beige whitespace-nowrap`}
  ${props => {
    return css`
      :first-of-type {
        background: ${props?.sizeGuide?.col1Color?.hex} !important;
      }
    `
  }}
`
