import React from "react"

import { withPopup } from "./withPopup"
import { Outer } from "./PopupStyles"
import { ModalContentsWithLayout, ModalExternal } from "../../../Modal/Modal"

export const Popup = withPopup(({ active, setActive, image }) => (
  <ModalExternal isOpen={active} setIsOpen={setActive}>
    <ModalContentsWithLayout layout={"sizeGuide"}>
      <Outer>
        <img src={image} alt={"size guide image"} />
      </Outer>
    </ModalContentsWithLayout>
  </ModalExternal>
))
