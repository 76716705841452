import React from "react"

import { useApp } from "../../../hooks/useApp"

export const withVariants = Component => ({ name = "Variants", handleVariant, selectedOptions, product }) => {
  const {
    config: {
      settings: {
        constraints: { COLOUR },
      },
    },
  } = useApp()

  Component.displayName = name
  return <Component handleVariant={handleVariant} options={product?.options} selectedOptions={selectedOptions} COLOUR={COLOUR} product={product} />
}
