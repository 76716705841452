import React from "react"
import { Link } from "gatsby"

import { withSwatches } from "./withSwatches"
import { Wrapper } from "./SwatchesStyles"
import { Swatch } from "./Swatch/Swatch"

export const Swatches = withSwatches(({ swatches, handle: selectedHandle }) => (
  <Wrapper>
    {swatches?.map(({ title, handle, image, url }) => (
      <Swatch key={handle} as={Link} to={url} title={title} disabled={selectedHandle === handle} image={image} active={selectedHandle === handle} />
    ))}
  </Wrapper>
))
