import React from "react"

import { withProduct } from "./withProduct"
import {
  Outer,
  Inner,
  ImagesWrapper,
  DetailsWrapper,
  Sticky,
  TitleWrapper,
  Title,
  BadgeWrapper,
  VariantsWrapper,
  Divider,
  BackToTop,
  StyledIcon,
} from "./ProductStyles"
import { Images } from "./Images/Images"
import { Price } from "./Price/Price"
import { Badge } from "./Badge/Badge"
import { AdditionalPayments } from "./AdditionalPayments/AdditionalPayments"
import { Swatches } from "./Swatches/Swatches"
import { Variants } from "./Variants/Variants"
import { SizeGuide } from "./SizeGuide/SizeGuide"
import { AddToCart } from "./AddToCart/AddToCart"
import { Description } from "./Description/Description"
import { Features } from "./Features/Features"
import { MoreInformation } from "./MoreInformation/MoreInformation"
import { Related } from "./Related/Related"
import { Popup as SizeGuidePopup } from "./SizeGuide/Popup/Popup"
import { Code } from "./Code/Code"
import { Share } from "./Share/Share"
import { BreadCrumbs } from "../BreadCrumbs/BreadCrumbs"
import { scrollTo } from "../../helpers/scrollTo"
import { Message } from "./Message/Message"

export const Product = withProduct(
  ({
    product: { title, handle, descriptionHtml, tags, metafields, media },
    product,
    image,
    spec,
    siblings,
    handleVariant,
    selectedOptions,
    selectedVariant,
    defaultVariant,
    multipleSiblings,
    multipleVariants,
    settingShowRelatedProducts,
    settingProductTemplateDetailV2,
    relatedProducts,
    relatedCollection,
    recommendations,
    sizeGuideActive,
    setSizeGuideActive,
    paths,
    isSizeGuideAvailable,
    sizeGuideImage,
  }): JSX.Element => (
    <Outer>
      <BreadCrumbs paths={paths} />
      <Inner>
        <ImagesWrapper>
          <Images media={media} />
        </ImagesWrapper>
        <DetailsWrapper>
          <Sticky>
            <BadgeWrapper>
              <Badge tags={tags} layout={"product"} />
              <Message tags={tags} layout={"product"} forceColour="black" />
            </BadgeWrapper>
            <TitleWrapper>
              <Title>{title}</Title>
              <Price variant={selectedVariant || defaultVariant} layout={"product"} />
            </TitleWrapper>
            <Description description={descriptionHtml} />
            <Code tags={tags} />
            {multipleSiblings ? <Swatches siblings={siblings} selectOptions={selectedOptions} handle={handle} /> : null}
            <VariantsWrapper>
              {isSizeGuideAvailable ? <SizeGuide tags={tags} /> : null}
              {multipleVariants ? <Variants handleVariant={handleVariant} selectedOptions={selectedOptions} product={product} /> : null}
            </VariantsWrapper>
            <AddToCart product={product} selectedVariant={selectedVariant} layout={"product"} />
            {/* Note: temporarily hide afterpay or additional payments */}
            {/* <AdditionalPayments variant={selectedVariant || defaultVariant} /> */}
            {settingProductTemplateDetailV2 ? <MoreInformation metafields={metafields} spec={spec} /> : null}
            <Features tags={tags} />
            <Divider />
            <Share handle={handle} image={image} />
          </Sticky>
          <BackToTop onClick={() => scrollTo(0)} title="Back to top">
            <StyledIcon name={"chevronDown"} width={14} height={14} />
            <span>Back to top</span>
          </BackToTop>
          <SizeGuidePopup active={sizeGuideActive} setActive={setSizeGuideActive} image={sizeGuideImage} />
        </DetailsWrapper>
      </Inner>
      {settingShowRelatedProducts ? (
        <Related recommendations={recommendations} relatedProducts={relatedProducts} relatedCollection={relatedCollection} />
      ) : null}
    </Outer>
  )
)
