import tw, { styled } from "twin.macro"
import { Icon } from "../../Icon/Icon"
import { Body } from "../../Styled/Text"

export const Outer = tw.div``

export const Inner = tw.div`
  flex flex-row items-center
`

export const Title = styled(Body)`
  ${tw`mb-1`}
`

export const StyledIcon = styled(Icon)`
  ${tw`mr-1`}
`
