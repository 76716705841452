import React from "react"
import { Link } from "gatsby"

import { withError } from "./withError"
import { Wrapper, Title, FeaturedProductsWrapper } from "./ErrorStyles"
import { RichText } from "../RichText/RichText"
import { StyledButton } from "../Styled/Button"
import { FeaturedProducts } from "../Sections/FeaturedProducts/FeaturedProducts"

export const Error = withError(
  ({ title, content, link, featuredTitle, featuredLink, collection, products }): JSX.Element => (
    <>
      <Wrapper>
        <Title>{title}</Title>
        <RichText>{content}</RichText>
        <StyledButton colour={"black-reverse"} as={Link} to={link.url} title={link.title}>
          {link.title}
        </StyledButton>
      </Wrapper>
      <FeaturedProductsWrapper>
        <FeaturedProducts title={featuredTitle} link={featuredLink} collection={collection} products={products} layout={"compact"} />
      </FeaturedProductsWrapper>
    </>
  )
)
