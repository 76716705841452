import { useCallback } from "react"

export const useHelper = () => {
  const fixedEncodeURIComponent = useCallback(str => {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
      return "%" + c.charCodeAt(0).toString(16)
    })
  }, [])

  return {
    fixedEncodeURIComponent,
  }
}
