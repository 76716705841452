import React from "react"
import { useShopify } from "../../../hooks/useShopify"

export const withGiftCard = Component => ({ name = "GiftCard", product, liveProduct }) => {
  const { imageNormaliser, productNormaliser } = useShopify()

  let rawProduct = undefined
  if (product.shopify?.raw) {
    try {
      rawProduct = JSON.parse(product.shopify?.raw)
    } catch (e) {
      console.error("Error parsing raw shopify product")
    }
  }

  const image = imageNormaliser({ src: product?.image }, 500)

  const item = productNormaliser(liveProduct?.product || rawProduct || product)

  Component.displayName = name
  return <Component
    product={product}
    variants={item.variants}
    image={image}
  />
}
