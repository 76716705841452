import React, { useEffect } from "react"
import { graphql } from "gatsby"

import { useCore } from "../hooks/useCore"
import { useShopify } from "../hooks/useShopify"
import { useQueries } from "../hooks/useQueries"

import { Product as Template } from "../components/Product/Product"
import { GiftCard } from "../components/Product/GiftCard/GiftCard"
import { Error } from "../components/Error/Error"
import { useApp } from "../hooks/useApp"
import { useLocation } from "../hooks/useLocation"

export const query = graphql`
  query ($handle: String!) {
    product: sanityProduct(shopify: { shopifyHandle: { eq: $handle } }) {
      ...GatsbySanityProductFragment
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      related: _rawRelated(resolveReferences: { maxDepth: 3 })
      siblings: _rawSiblings(resolveReferences: { maxDepth: 2 })
      collection: _rawCollection(resolveReferences: { maxDepth: 3 })
    }
    giftcard: sanityPageGiftcard {
      unavailableMessage: _rawUnavailableMessage(resolveReferences: { maxDepth: 3 })
      unavailableTitle
    }
    errorPage: sanityPageError {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
      link: _rawLink(resolveReferences: { maxDepth: 2 })
      collection: _rawCollection(resolveReferences: { maxDepth: 3 })
      products: _rawProducts(resolveReferences: { maxDepth: 3 })
      featuredLink: _rawFeaturedLink(resolveReferences: { maxDepth: 2 })
      featuredTitle
    }
    template: sanityTemplateProduct {
      settingGiftCardProduct {
        shopify {
          id: shopifyId
          handle: shopifyHandle
          raw: shopifyRaw
        }
      }
      settingSizeGuides: _rawSettingSizeGuide(resolveReferences: { maxDepth: 4 })
      settingShowRelatedProducts
      settingProductTemplateDetailV2
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

export default ({ data, ...props }): JSX.Element => {
  const {
    helpers: { encodeShopifyId },
  } = useCore()

  const {
    config: {
      settings: {
        constraints: { productMetafields },
      },
    },
  } = useApp()

  const {
    queries: { GET_PRODUCT_COMPLETE, GET_PRODUCT_AVAILABILITY },
  } = useQueries()

  const { location } = useLocation()

  const { useLazyQuery } = useShopify()

  const {
    product: {
      shopify: { id, handle },
      siblings,
    },
    template,
  } = data

  const giftCardHandle = template?.settingGiftCardProduct?.shopify?.handle

  const [getProduct, { data: liveProduct, called: getProductCalled, loading, error }] = useLazyQuery(GET_PRODUCT_COMPLETE, {
    fetchPolicy: `cache-and-network`,
    nextFetchPolicy: "cache-first",
    variables: {
      parentQuery: `tag:'colours:${handle}'`,
      countryCode: location,
      id: encodeShopifyId(id, "Product"),
      handle,
      firstImages: 15,
      firstMedia: 15,
      firstVariants: 30,
      firstCollections: 1,
      metafieldIdentifiers: productMetafields,
    },
  })

  const [getGiftCard, { data: liveGiftCard, called: getGiftCardCalled, loading: getGiftCardLoading, error: getGiftCardError }] = useLazyQuery(
    GET_PRODUCT_AVAILABILITY,
    {
      fetchPolicy: `cache-and-network`,
      nextFetchPolicy: "cache-first",
      variables: {
        id: encodeShopifyId(id, "Product"),
        handle: giftCardHandle,
        countryCode: location,
        firstVariants: 2,
      },
    }
  )

  useEffect(() => {
    if (!handle || getProductCalled) return
    getProduct()
  }, [handle, getProductCalled, getProduct])

  useEffect(() => {
    if (!giftCardHandle || !getGiftCard || handle !== giftCardHandle) return
    getGiftCard()
  }, [handle, giftCardHandle, getGiftCard])

  useEffect(() => {
    console.log(liveGiftCard, getGiftCardCalled)
  }, [liveGiftCard, getGiftCardCalled])

  if (error) console.error(error)

  return handle === giftCardHandle ? (
    getGiftCardLoading ? (
      <div>Loading...</div>
    ) : liveGiftCard?.product && location === "AU" ? (
      <GiftCard {...props} {...data} liveProduct={liveProduct} loading={loading} isAvailable={liveGiftCard?.product} />
    ) : (
      <Error
        page={data?.errorPage}
        content={data?.giftcard?.unavailableMessage ?? "test"}
        overrideTitle={data?.giftcard?.unavailableTitle ?? "test 2"}
      />
    )
  ) : (
    <Template {...props} {...data} liveProduct={liveProduct} loading={loading} siblings={siblings} />
  )
}
