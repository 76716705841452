import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`max-w-xl mx-auto px-2-4 md:px-7-5 pt-9 md:pt-16 pb-6 md:pb-12 text-center`}
  p > a {
    ${tw`px-0`}
  }
  a {
    ${tw`mt-3-2 px-2-4`}
  }
`

export const Title = tw.h1`
  text-18 leading-none  md:text-30 md:leading-none  text-center uppercase font-bold mb-3-2
`

export const FeaturedProductsWrapper = tw.div`
  border-t border-black pt-3-2 md:pt-8 pb-4 md:pb-6-8 max-w-xl mx-auto md:px-7-5
`
