import tw, { styled } from "twin.macro"

export const Wrapper = styled.li`
  ${tw`border-t border-grey py-0-8 text-12 text-left w-full flex flex-row justify-between cursor-pointer`}
  ${({ isNoStock }) => (isNoStock ? tw`text-grey` : null)}
`

export const AdditionalContent = tw.span`
  block text-white
`
