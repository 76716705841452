import React from "react"

import { useRoutes } from "../../hooks/useRoutes"
import { useMounted } from "../../hooks/useMounted"

export const withError =
  Component =>
  ({ name = "Error", page, content: giftCardContent, overrideTitle }) => {
    const { linkResolver } = useRoutes()

    const { hasMounted } = useMounted()

    const { title, content, link: originalLink, collection, products, featuredLink, featuredTitle } = page

    const link = linkResolver(originalLink)

    Component.displayName = name
    return hasMounted ? (
      <Component
        title={overrideTitle || title}
        content={giftCardContent || content}
        link={link}
        featuredTitle={featuredTitle}
        featuredLink={featuredLink}
        collection={collection}
        products={products}
      />
    ) : null
  }
