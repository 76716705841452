import React from "react"

import { Wrapper, Colour } from "./SwatchStyles"

export const Swatch = ({ active, image, className = "", ...props }) => {
  return (
    <Wrapper className={className} active={active}>
      <Colour image={image} {...props} />
    </Wrapper>
  )
}
