import tw, { styled } from "twin.macro"
import { Icon } from "../Icon/Icon"
import { Heading12 } from "../Styled/Text"

export const Outer = tw.div``

export const Inner = tw.div`flex flex-col md:flex-row max-w-xl mx-auto relative`

export const ImagesWrapper = tw.div`w-full md:w-1/2 mlg:w-2/3 md:flex-1`

export const DetailsWrapper = tw.div`w-full md:w-1/2 mlg:w-1/3 px-2 flex flex-col justify-between`

export const Sticky = tw.div`
  md:sticky md:top-10 md:w-34 md:mx-auto pt-4 pb-4 md:pb-8
`

export const TitleWrapper = tw.div`
  flex flex-row mb-1-2 items-center justify-between
`

export const Title = styled(Heading12)`
  ${tw`uppercase block`}
`

export const BadgeWrapper = tw.div`
  flex flex-row items-center
`

export const VariantsWrapper = tw.div`
  flex flex-col items-end mb-1-6
`

export const Divider = tw.div`
  border-t border-grey mb-1-6
`

export const BackToTop = tw.button`
  hidden md:flex relative z-10 items-start md:w-34 my-2 mx-auto cursor-pointer text-12
`

export const StyledIcon = styled(Icon)`
  ${tw`transform rotate-180 mr-1 inline-block`}
`
