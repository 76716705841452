import tw, { styled } from "twin.macro"

export const Wrapper = tw.div` 
  mb-0-4 relative
`

export const SelectedOption = styled.div`
  ${tw`flex justify-between items-center cursor-pointer pl-1 border border-black`}
  ${({ active }) => (active ? tw`border-b-0 pb-0-1` : tw``)}
`

export const IconWrapper = styled.div`
  ${tw`text-black`}
  ${({ active }) => (active ? null : tw`border-l border-black`)}
`

export const Options = styled.div`
  ${tw`flex-col px-1 border-b border-l border-r border-black absolute top-full inset-x-0 z-10 bg-white max-h-3/10-vh overflow-y-auto`}
  ${({ active }) => (active ? tw`flex` : tw`hidden`)}
`
