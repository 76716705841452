import React from "react"
import tw from "twin.macro"

import { StyledInput, StyledInputWrapper, StyledLabel } from "../Styled/Form"

export const TextLengthLimitationMessage = tw.p`
  -mt-0-4 mb-0-8 text-grey text-10 block
`

export const TextField = ({ label, name, value, limit, lengthLimitMessage, ...props }) => (
  <StyledInputWrapper>
    <StyledLabel htmlFor={name}>{label}</StyledLabel>
    <StyledInput id={name} name={name} value={value} {...props} />
    {Boolean(limit) && Boolean(lengthLimitMessage) ? (
      <TextLengthLimitationMessage>{lengthLimitMessage.replace("${amount}", (limit - value.length).toString())}</TextLengthLimitationMessage>
    ) : null}
  </StyledInputWrapper>
)
