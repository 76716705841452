import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  mb-0-8
`
export const RichText = styled.div`
  ${tw`text-12`}
  > *, > div > * {
    ${tw`mb-1-6 last:mb-0`}
  }
  li {
    ${tw`mt-1`}
  }
`
