import React, { useEffect, useState } from "react"
import { useApp } from "../../hooks/useApp"
import { useRoutes } from "../../hooks/useRoutes"
import { useKlaviyo } from "../../hooks/useKlaviyo"

import { useShopify, useShopifyVariants } from "../../hooks/useShopify"

export const withProduct = Component => ({ name = "Product", product, siblings, template, liveProduct, loading, location }) => {
  const {
    config: {
      settings: {
        tags: { sizeGuide: sizeGuideTagPrefix },
      },
    },
  } = useApp()

  const { edgeNormaliser, productNormaliser } = useShopify()
  const { trackKlaviyoProductView } = useKlaviyo()
  const { routeResolver } = useRoutes()

  const [sizeGuideActive, setSizeGuideActive] = useState(false)

  let rawProduct = undefined
  if (product.shopify?.raw) {
    try {
      rawProduct = JSON.parse(product.shopify?.raw)
    } catch (e) {
      console.error("Error parsing raw shopify product")
    }
  }

  const item = productNormaliser(liveProduct?.product || rawProduct || product)

  useEffect(() => {
    if(liveProduct) {
      trackKlaviyoProductView(item);
    }
  }, [liveProduct])

  const variants = edgeNormaliser(liveProduct?.product?.variants)

  const multipleSiblings = siblings?.length > 0 || false

  const multipleVariants = item?.variants.length > 1

  const { localActiveVariant, selectedOptions, handleVariant } = useShopifyVariants({
    useParameter: true,
    product: { variants: variants },
    loading,
    noDefaultSelectedVariant: multipleVariants,
    firstAvailable: !multipleVariants,
  })

  const { settingShowRelatedProducts, settingProductTemplateDetailV2, settingSizeGuides } = template

  const { related: relatedProducts, collection: relatedCollection } = product

  const recommendations = liveProduct?.recommendations

  const primaryCollection = product?.primaryCollection

  const parentCollection = React.useMemo(() => {
    if (!location?.state?.collection && !primaryCollection) return null
    return location?.state?.collection ? location?.state?.collection : primaryCollection
  }, [primaryCollection, location?.state?.collection])

  const path = parentCollection ? routeResolver({ item: parentCollection, type: `collection` }) : null

  const paths = parentCollection
    ? [
        {
          title: parentCollection.title,
          url: path,
        },
        {
          title: item.title,
        },
      ]
    : [
        {
          title: item.title,
        },
      ]

  const sizeGuideTag = item?.tags
    ?.find(tag => tag.includes(sizeGuideTagPrefix))
    ?.split(":")?.[1]
    .toLowerCase()

  // const sizeGuideImage = settingSizeGuides?.find(settingSizeGuide => settingSizeGuide?.title.toLowerCase() === sizeGuideTag)?.image
  const sizeGuideImageUrl = `https://res.cloudinary.com/kidman2024/Driza-Bone/${sizeGuideTag}`
  const isSizeGuideAvailable = sizeGuideTag?.length > 0

  Component.displayName = name
  return (
    <Component
      product={item}
      image={product.image}
      siblings={siblings}
      spec={product.spec}
      handleVariant={handleVariant}
      selectedOptions={selectedOptions}
      selectedVariant={localActiveVariant}
      defaultVariant={item.variants?.[0]}
      multipleSiblings={multipleSiblings}
      multipleVariants={multipleVariants}
      settingShowRelatedProducts={settingShowRelatedProducts}
      settingProductTemplateDetailV2={settingProductTemplateDetailV2}
      relatedProducts={relatedProducts}
      relatedCollection={relatedCollection}
      recommendations={recommendations}
      sizeGuideActive={sizeGuideActive}
      setSizeGuideActive={setSizeGuideActive}
      paths={paths}
      isSizeGuideAvailable={isSizeGuideAvailable}
      sizeGuideImage={sizeGuideImageUrl}
    />
  )
}
