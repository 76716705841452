import React from "react"

import { Column, Row, Scroll, Table as Wrapper } from "./SizeGuideStyles"

export const Table = ({ chart, sizeGuide }) =>
  chart ? (
    <Scroll>
      <Wrapper>
        {chart?.rows?.map((row, index) => (
          <Row sizeGuide={sizeGuide} key={`${row?.key}_${index}`}>
            {row?.cells?.map((column, index) => (
              <Column sizeGuide={sizeGuide} key={`${column}_${index}`}>
                {column || <div dangerouslySetInnerHTML={{ __html: `&nbsp;` }} />}
              </Column>
            ))}
          </Row>
        ))}
      </Wrapper>
    </Scroll>
  ) : null
