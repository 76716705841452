import React from "react"

import { withVariants } from "./withVariants"
import { Wrapper } from "./VariantsStyles"
import { Variant } from "./Variant/Variant"

export const Variants = withVariants(({ handleVariant, options, selectedOptions, COLOUR, product }) =>
  options?.length > 0 ? (
    <Wrapper>
      {options?.map((option, index) =>
        option.name !== COLOUR ? (
          <Variant
            key={option?.name?.toString() + index}
            option={option}
            selectedOptions={selectedOptions}
            handleVariant={handleVariant}
            product={product}
          />
        ) : null
      )}
    </Wrapper>
  ) : null
)
