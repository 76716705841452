import React from "react"

import { withOption } from "./withOption"
import { Wrapper, AdditionalContent } from "./OptionStyles"

export const Option = withOption(({ handleVariant, name, value, selectedVariant, displayRestock, lowStockThreshold }) => (
  <Wrapper onClick={() => handleVariant({ name, value })} noStock={false} isNoStock={!selectedVariant.availableForSale}>
    <span>{value}</span>
    {!selectedVariant.availableForSale ? (
      <AdditionalContent>{displayRestock ? `Notify me` : `Out of Stock`}</AdditionalContent>
    ) : selectedVariant.availableForSale && selectedVariant.currentlyNotInStock ? (
      <AdditionalContent>Pre-order</AdditionalContent>
    ) : selectedVariant.quantityAvailable < lowStockThreshold ? (
      <AdditionalContent>Low stock</AdditionalContent>
    ) : null}
  </Wrapper>
))
