import React, { useState } from "react"

import { withGiftCardForm } from "./withGiftCardForm"
import { Input } from "../../../Form/Input"
import { Textarea } from "../../../Form/Textarea"
import { TextField } from "../../../Form/TextField"
import { Title } from "../GiftCardStyles"
import { Label, PresetAmount, ErrorMessage, PresetAmountWrapper, AddToCartButton, ButtonInnerWrapper, NameWrapper, Message } from "./GiftCardFormStyles"
import { Icon } from "../../../Icon/Icon"

export const GiftCardForm = withGiftCardForm(
  ({
    data,
    handleChange,
    handleClick,
    handleSubmit,
    remainingWords,
    loading,
    settingGiftCardMaxLength,
    addToCartButtonText,
    additionalGiftCardCustomAmountPlaceholder,
    additionalGiftCardCustomAmountText,
    additionalGiftCardFormTitle,
    additionalGiftCardLengthMessage,
    additionalGiftCardMessageLabel,
    additionalGiftCardMessagePlaceholder,
    additionalGiftCardReceiverEmailLabel,
    additionalGiftCardReceiverLabel,
    additionalGiftCardSelectAmountLabel,
    additionalGiftCardSenderLabel,
    settingGiftCardPresetValue,
    error,
    variants
  }) => {
    const [selectedVariant, setSelectedVariant] = useState(variants[0])

    const handleVariantChange = (variant) => {
      setSelectedVariant(variant)
    }

    return (
      <form onSubmit={(e) => handleSubmit(e, selectedVariant)}>
        <Title>{additionalGiftCardFormTitle}</Title>

        <Label>{additionalGiftCardSelectAmountLabel}</Label>
        <PresetAmountWrapper>
          {variants.map((variant) => (
            console.log(variant),
            <PresetAmount 
              type="button" 
              key={variant.id} 
              onClick={() => handleVariantChange(variant)} 
              active={selectedVariant.id === variant.id}
            >
              {variant.title.split('/')[0].trim()}
            </PresetAmount>
          ))}
        </PresetAmountWrapper>
        
        {error && (
          <ErrorMessage color="red">{error}</ErrorMessage>
        )}

        <NameWrapper>
          <TextField
            type="text"
            name="receiver"
            value={data?.receiver}
            onChange={handleChange}
            label={additionalGiftCardReceiverLabel}
            limit={50}
            maxLength={50}
            lengthLimitMessage={"${amount} characters left"}
            spacing="small"
          />
          <TextField
            type="text"
            name="sender"
            value={data?.sender}
            onChange={handleChange}
            label={additionalGiftCardSenderLabel}
            spacing="small"
            limit={50}
            maxLength={50}
            lengthLimitMessage={"${amount} characters left"}
          />
        </NameWrapper>
        <Input
          type="email"
          name="email"
          value={data?.email}
          onChange={handleChange}
          label={additionalGiftCardReceiverEmailLabel}
          spacing="small"
          required
        />
        <Textarea
          name="message"
          value={data?.message}
          onChange={handleChange}
          label={additionalGiftCardMessageLabel}
          placeholder={additionalGiftCardMessagePlaceholder}
          spacing="small"
          maxlength={settingGiftCardMaxLength}
        />
        <Message>{additionalGiftCardLengthMessage?.replace("${amount}", remainingWords)}</Message>
        <AddToCartButton type="submit" colour="yellow" wide>
          <ButtonInnerWrapper>
            <Icon name="bag" />
            <span>{loading ? "adding" : addToCartButtonText}</span>
          </ButtonInnerWrapper>
        </AddToCartButton>
      </form>
    )
  }
)