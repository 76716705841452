import React from "react"

import { withRelated } from "./withRelated"
import { Wrapper } from "./RelatedStyles"
import { FeaturedProducts } from "../../Sections/FeaturedProducts/FeaturedProducts"

export const Related = withRelated(({ title, recommendations, relatedProducts, relatedCollection }) =>
  recommendations?.length > 0 || relatedProducts?.length > 0 || relatedCollection ? (
    <Wrapper>
      <FeaturedProducts collection={relatedCollection} products={relatedProducts || recommendations} title={title} />
    </Wrapper>
  ) : null
)
