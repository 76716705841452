import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useHelper } from "../../../hooks/useHelper"
import { useApp } from "../../../hooks/useApp"

export const withShare = Component => ({ name = "Share", handle, image }) => {
  const {
    config: {
      settings: { routes },
      app: { url },
    },
  } = useApp()
  const { fixedEncodeURIComponent } = useHelper()

  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_SOCIAL_SHARE {
      productTemplateData: sanityTemplateProduct {
        settingSocialShareMethods {
          name
          _key
        }
        additionalSocialShareText
        additionalSocialShareMessage
      }
    }
  `)

  const { settingSocialShareMethods, additionalSocialShareText, additionalSocialShareMessage } = productTemplateData || {}

  const handleClick = method => {
    const encodedUrl = fixedEncodeURIComponent(`${url}${routes.PRODUCT}/${handle}`)
    const encodedSubject = fixedEncodeURIComponent(additionalSocialShareMessage)
    const encodedImageUrl = fixedEncodeURIComponent(image)

    switch (method) {
      case "mail":
        window.open(`mailto:?subject=${encodedSubject}&body=${encodedUrl}`, "_blank")
        break
      case "facebook":
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`, "facebook-popup", "height=400,width=600")
        break
      case "twitter":
        window.open(`https://twitter.com/intent/tweet?text=${encodedSubject}%20${encodedUrl}`)
        break
      case "pinterest":
        window.open(`http://pinterest.com/pin/create/button/?url=${encodedUrl}&media=${encodedImageUrl}`)
        break
      case "whatsapp":
        window.open(`https://api.whatsapp.com/send?text=${encodedSubject}%20${encodedUrl}`)
        break
      default:
        break
    }
  }

  Component.displayName = name
  return settingSocialShareMethods?.length > 0 ? (
    <Component methods={settingSocialShareMethods} title={additionalSocialShareText} handleClick={handleClick} />
  ) : null
}
